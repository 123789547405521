const en = {
  // Navbars
  Benefits: "Benefits",
  "Scope of work": "Scope of work",
  Login: "Login",

  // SectionHeroBlock
  "A Digital Agency with a Unique Approach":
    "A Digital Agency with a Unique Approach",
  "Subscriptions for All. Pause or Cancel Anytime.":
    "Subscriptions for All. Pause or Cancel Anytime.",
  guaranteeBlock: `<span>Digitaltech you'll</span>
  &nbsp;
  <img
    src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-6-heart.svg"
    loading="lazy"
    alt=""
    class="guarnatee__heart"
  />
  <span>, guaranteed</span>`,

  // PromiseBlock
  "We crafted digital experiences, not just websites.":
    "We crafted digital experiences, not just websites.",
  "The old way of web development is gone. Welcome to tailored, digital solutions that meet your specific needs.":
    "The old way of web development is gone. Welcome to tailored, digital solutions that meet your specific needs.",
  "Subscribe to a plan & request as many web development or digital solution services as you'd like.":
    "Subscribe to a plan & request as many web development or digital solution services as you'd like.",
  "Receive your solutions within a few business days on average, Monday to Friday.":
    "Receive your solutions within a few business days on average, Monday to Friday.",
  "We'll refine the solutions until you're 100% satisfied.":
    "We'll refine the solutions until you're 100% satisfied.",
  "DigitechJoy demonstrates expertise in the digital realm.":
    "DigitechJoy demonstrates expertise in the digital realm.",

  // advantageBlock
  advantageBlock_1:
    'It’s “you’ll <span class="never">never</span> go back” better',
  "DigitechJoy replaces inconsistent freelancers and costly agencies with one flat monthly fee, delivering solutions so efficiently, it will impress you.":
    "DigitechJoy replaces inconsistent freelancers and costly agencies with one flat monthly fee, delivering solutions so efficiently, it will impress you.",
  "Digital excellence is key, and DigitechJoy has mastered it.":
    "Digital excellence is key, and DigitechJoy has mastered it.",

  "Totally async": "Totally async",
  "Don't like meetings? We don't either; so much so that we've outlawed them completely.":
    "Don't like meetings? We don't either; so much so that we've outlawed them completely.",
  "Manage with Notion": "Manage with Notion",
  "Manage your website board using Notion. View active, queued and completed tasks with ease.":
    "Manage your website board using Notion. View active, queued and completed tasks with ease.",
  "Invite unlimited team members": "Invite unlimited team members",
  "Invite your entire team, so anyone can submit requests and track their progress.":
    "Invite your entire team, so anyone can submit requests and track their progress.",

  // Membership benefits
  "Membership benefits": "Membership benefits",
  "Perks so good you'll never need to go anywhere else for your web and digital solutions. Seriously.":
    "Perks so good you'll never need to go anywhere else for your web and digital solutions. Seriously.",

  "Design board": "Design board",
  "Add as many digitaltech requests to your board as you'd like":
    "Add as many digitaltech requests to your board as you'd like",
  "Lightning fast delivery": "Lightning fast delivery",
  "Get your digitalsolution one at a time in just a few days on average.":
    "Get your digitalsolution one at a time in just a few days on average.",
  "Fixed monthly rate": "Fixed monthly rate",
  "No surprises here! Pay the same fixed price each month.":
    "No surprises here! Pay the same fixed price each month.",
  "Top-notch quality": "Top-notch quality",
  "Outstanding digital solutions at your fingertips whenever you need them.":
    "Outstanding digital solutions at your fingertips whenever you need them.",
  "Flexible and Scalable": "Flexible and Scalable",
  "Scale up or down as needed, and pause or cancel at anytime.":
    "Scale up or down as needed, and pause or cancel at anytime.",
  "Unique and all yours": "Unique and all yours",
  "Each of your digisolution is made especially for you and is 100% yours.":
    "Each of your digisolution is made especially for you and is 100% yours.",

  // RecentWorkBlock
  "Recent work": "Recent work",
  "Award winning digisolution, and nothing less.":
    "Award winning digisolution, and nothing less.",
  "View recent work": "View recent work",

  // ServicesListBlock
  "Websites, applications, digitalization & more.":
    "Websites, applications, digitalization & more.",

  // ServicesListBlock - 1. WordPress Website Development
  "WordPress Website Development": "WordPress Website Development",
  "Custom WordPress website design and development, suitable for various business needs.":
    "Custom WordPress website design and development, suitable for various business needs.",
  "Includes responsive design to ensure the website performs well on all devices.":
    "Includes responsive design to ensure the website performs well on all devices.",
  // ServicesListBlock - 2. Performance Optimization
  "Performance Optimization": "Performance Optimization",
  "Website performance analysis and optimization to improve loading speed and user experience.":
    "Website performance analysis and optimization to improve loading speed and user experience.",
  "Basic SEO optimization to enhance website ranking in search engines.":
    "Basic SEO optimization to enhance website ranking in search engines.",
  // ServicesListBlock - 3. Technical Training and Support
  "Technical Training and Support": "Technical Training and Support",
  "Provides training on WordPress and related technologies.":
    "Provides training on WordPress and related technologies.",
  "Teaches clients how to independently manage and update their websites.":
    "Teaches clients how to independently manage and update their websites.",
  // ServicesListBlock - 4. Maintenance and Support
  "Maintenance and Support": "Maintenance and Support",
  "Provides regular maintenance for websites, including updates, backups, security monitoring, etc.":
    "Provides regular maintenance for websites, including updates, backups, security monitoring, etc.",
  "Quick response to solve operational issues with websites.":
    "Quick response to solve operational issues with websites.",
  // ServicesListBlock - 5. Digital Solution Consulting
  "Digital Solution Consulting": "Digital Solution Consulting",
  "Assists businesses in assessing and optimizing their digital strategies.":
    "Assists businesses in assessing and optimizing their digital strategies.",
  "Offers customized technical solutions to enhance business efficiency and online performance.":
    "Offers customized technical solutions to enhance business efficiency and online performance.",

  // PricingBlock
  "Choose a plan that works for you": "Choose a plan that works for you",
  "No contracts or surprises. Cancel anytime.":
    "No contracts or surprises. Cancel anytime.",
  "MOST POPULAR": "MOST POPULAR",

  "Start today": "Start today",
  "Book a call": "Book a call",
  "What's included": "What's included",

  Standard: "Standard",
  "For professionals, startups, and enterprises with ongoing website update needs.":
    "For professionals, startups, and enterprises with ongoing website update needs.",
  "€499/m": "€499/m",
  "One project at a time": "One project at a time",
  "Front-end development with Wordpress":
    "Front-end development with Wordpress",

  Pro: "Pro",
  "For those in need of design and front-end development.":
    "For those in need of design and front-end development.",
  "€999/m": "€999/m",
  "Two projects at a time": "Two projects at a time",
  "Development and design with WordPress":
    "Development and design with WordPress",

  "Unlimited requests": "Unlimited requests",
  "Unlimited revisions": "Unlimited revisions",
  "Unlimited team members": "Unlimited team members",
  "Average one week delivery (with existing design drafts)":
    "Average one week delivery (with existing design drafts)",
  "Basic website maintenance": "Basic website maintenance",
  "Easy credit-card payments": "Easy credit-card payments",
  "Pause or cancel anytime": "Pause or cancel anytime",

  "Learn more about how DigitechJoy works and how it can help you.":
    "Learn more about how DigitechJoy works and how it can help you.",

  "Refer a friend & earn": "Refer a friend & earn",
  "Earn 5% monthly recurring commissions for each referral.":
    "Earn 5% monthly recurring commissions for each referral.",
  "Join now": "Join now",

  Must: "Must",
  "Server Hosting Servcie": "Server Hosting Servcie",
  "For customers who do not require our development services, we offer separate Server Hosting Servcie. This fee ensures that your website runs stably on the Internet.":
    "For customers who do not require our development services, we offer separate Server Hosting Servcie. This fee ensures that your website runs stably on the Internet.",
  "€15/m": "€15/m",

  // FAQs
  FAQs: "FAQs",

  // FooterBlock
  "See if DigitechJoy is right for you. (It totally is.)":
    "See if DigitechJoy is right for you. (It totally is.)",
  "Get a guided tour through Designjoy, and find out how you and your team can change the way you source design, forever.":
    "Get a guided tour through Designjoy, and find out how you and your team can change the way you source design, forever.",

  "DigitechJoy is headquartered in Paris, France.":
    "DigitechJoy is headquartered in Paris, France.",

  Pricing: "Pricing",
  Contact: "Contact",
  "Client login": "Client login",
  "Terms & conditions": "Terms & conditions",
  "Privacy policy": "Privacy policy",

  // Buttons
  "Get started": "Get started",
  "See plans": "See plans",
};

const fr = {
  // Navbars
  Benefits: "Avantages",
  "Scope of work": "Étendue des travaux",
  Login: "Connexion",

  // SectionHeroBlock
  "A Digital Agency with a Unique Approach":
    "Une agence numérique avec une approche unique",
  "Subscriptions for All. Pause or Cancel Anytime.":
    "Abonnements pour tous. Pause ou annulation à tout moment.",
  guaranteeBlock: `<span>Technologie numérique que vous allez</span>
  &nbsp;
  <img
    src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-6-heart.svg"
    loading="lazy"
    alt=""
    class="guarnatee__heart"
  />
  <span>, garantie</span>`,

  // PromiseBlock
  "We crafted digital experiences, not just websites.":
    "Nous créons des expériences numériques, pas seulement des sites web.",
  "The old way of web development is gone. Welcome to tailored, digital solutions that meet your specific needs.":
    "L'ancienne méthode de développement web est révolue. Bienvenue dans des solutions numériques sur mesure qui répondent à vos besoins spécifiques.",
  "Subscribe to a plan & request as many web development or digital solution services as you'd like.":
    "Abonnez-vous à un plan et demandez autant de services de développement web ou de solutions numériques que vous le souhaitez.",
  "Receive your solutions within a few business days on average, Monday to Friday.":
    "Recevez vos solutions en quelques jours ouvrables en moyenne, du lundi au vendredi.",
  "We'll refine the solutions until you're 100% satisfied.":
    "Nous peaufinerons les solutions jusqu'à ce que vous soyez entièrement satisfait.",
  "DigitechJoy demonstrates expertise in the digital realm.":
    "DigitechJoy démontre son expertise dans le domaine numérique.",

  // advantageBlock
  advantageBlock_1:
    'C’est une expérience tellement meilleure que vous ne voudrez <span class="never">jamais</span> revenir en arrière',
  "DigitechJoy replaces inconsistent freelancers and costly agencies with one flat monthly fee, delivering solutions so efficiently, it will impress you.":
    "DigitechJoy remplace les freelancers incohérents et les agences coûteuses par un tarif mensuel fixe, offrant des solutions si efficaces que cela vous impressionnera.",
  "Digital excellence is key, and DigitechJoy has mastered it.":
    "L'excellence numérique est essentielle, et DigitechJoy l'a maîtrisée.",

  "Totally async": "Entièrement asynchrone",
  "Don't like meetings? We don't either; so much so that we've outlawed them completely.":
    "Vous n'aimez pas les réunions ? Nous non plus ; à tel point que nous les avons complètement interdites.",
  "Manage with Notion": "Gérez avec Notion",
  "Manage your website board using Notion. View active, queued and completed tasks with ease.":
    "Gérez votre tableau de site web avec Notion. Visualisez facilement les tâches actives, en attente et terminées.",
  "Invite unlimited team members":
    "Invitez un nombre illimité de membres de l'équipe",
  "Invite your entire team, so anyone can submit requests and track their progress.":
    "Invitez toute votre équipe, afin que chacun puisse soumettre des demandes et suivre leur progression.",

  // Membership benefits
  "Membership benefits": "Avantages des membres",
  "Perks so good you'll never need to go anywhere else for your web and digital solutions. Seriously.":
    "Des avantages si bons que vous n'aurez jamais besoin d'aller ailleurs pour vos solutions web et numériques. Sérieusement.",

  "Design board": "Tableau de conception",
  "Add as many digitaltech requests to your board as you'd like":
    "Ajoutez autant de demandes de technologie numérique que vous le souhaitez à votre tableau",
  "Lightning fast delivery": "Livraison ultra rapide",
  "Get your digitalsolution one at a time in just a few days on average.":
    "Obtenez vos solutions numériques une par une en seulement quelques jours en moyenne.",
  "Fixed monthly rate": "Tarif mensuel fixe",
  "No surprises here! Pay the same fixed price each month.":
    "Pas de surprises ici ! Payez le même prix fixe chaque mois.",
  "Top-notch quality": "Qualité de premier ordre",
  "Outstanding digital solutions at your fingertips whenever you need them.":
    "Des solutions numériques exceptionnelles à portée de main chaque fois que vous en avez besoin.",
  "Flexible and Scalable": "Flexible et évolutif",
  "Scale up or down as needed, and pause or cancel at anytime.":
    "Augmentez ou réduisez à votre convenance, et mettez en pause ou annulez à tout moment.",
  "Unique and all yours": "Unique et entièrement à vous",
  "Each of your digisolution is made especially for you and is 100% yours.":
    "Chacune de vos solutions numériques est spécialement conçue pour vous et vous appartient à 100 %.",

  // RecentWorkBlock
  "Recent work": "Travaux récents",
  "Award winning digisolution, and nothing less.":
    "Solution numérique primée, et rien de moins.",
  "View recent work": "Voir les travaux récents",

  // ServicesListBlock
  "Websites, applications, digitalization & more.":
    "Sites web, applications, numérisation et plus encore.",

  // ServicesListBlock - 1. WordPress Website Development
  "WordPress Website Development": "Développement de sites web WordPress",
  "Custom WordPress website design and development, suitable for various business needs.":
    "Conception et développement de sites web WordPress personnalisés, adaptés à divers besoins professionnels.",
  "Includes responsive design to ensure the website performs well on all devices.":
    "Comprend un design réactif pour assurer une bonne performance du site sur tous les appareils.",
  // ServicesListBlock - 2. Performance Optimization
  "Performance Optimization": "Optimisation des performances",
  "Website performance analysis and optimization to improve loading speed and user experience.":
    "Analyse et optimisation des performances du site web pour améliorer la vitesse de chargement et l'expérience utilisateur.",
  "Basic SEO optimization to enhance website ranking in search engines.":
    "Optimisation SEO de base pour améliorer le classement du site dans les moteurs de recherche.",
  // ServicesListBlock - 3. Technical Training and Support
  "Technical Training and Support": "Formation technique et support",
  "Provides training on WordPress and related technologies.":
    "Fournit une formation sur WordPress et les technologies associées.",
  "Teaches clients how to independently manage and update their websites.":
    "Enseigne aux clients comment gérer et mettre à jour leurs sites web de manière autonome.",
  // ServicesListBlock - 4. Maintenance and Support
  "Maintenance and Support": "Maintenance et support",
  "Provides regular maintenance for websites, including updates, backups, security monitoring, etc.":
    "Fournit une maintenance régulière des sites web, y compris les mises à jour, les sauvegardes, la surveillance de la sécurité, etc.",
  "Quick response to solve operational issues with websites.":
    "Réponse rapide pour résoudre les problèmes opérationnels avec les sites web.",
  // ServicesListBlock - 5. Digital Solution Consulting
  "Digital Solution Consulting": "Consultation en solutions numériques",
  "Assists businesses in assessing and optimizing their digital strategies.":
    "Aide les entreprises à évaluer et à optimiser leurs stratégies numériques.",
  "Offers customized technical solutions to enhance business efficiency and online performance.":
    "Propose des solutions techniques personnalisées pour améliorer l'efficacité des entreprises et les performances en ligne.",

  // PricingBlock
  "Choose a plan that works for you": "Choisissez un plan qui vous convient",
  "No contracts or surprises. Cancel anytime.":
    "Pas de contrats ni de surprises. Annulez à tout moment.",
  "MOST POPULAR": "LE PLUS POPULAIRE",

  "Start today": "Commencez aujourd'hui",
  "Book a call": "Prenez rendez-vous",
  "What's included": "Ce qui est inclus",

  Standard: "Standard",
  "For professionals, startups, and enterprises with ongoing website update needs.":
    "Pour les professionnels, les startups et les entreprises ayant des besoins continus de mise à jour de site web.",
  "€499/m": "€499/m",
  "One project at a time": "Un projet à la fois",
  "Front-end development with Wordpress":
    "Développement front-end avec WordPress",

  Pro: "Pro",
  "For those in need of design and front-end development.":
    "Pour ceux qui ont besoin de conception et de développement front-end.",
  "€999/m": "€999/m",
  "Two projects at a time": "Deux projets à la fois",
  "Development and design with WordPress":
    "Développement et design avec WordPress",

  "Unlimited requests": "Demandes illimitées",
  "Unlimited revisions": "Révisions illimitées",
  "Unlimited team members": "Membres de l'équipe illimités",
  "Average one week delivery (with existing design drafts)":
    "Livraison moyenne en une semaine (avec des ébauches de conception existantes)",
  "Basic website maintenance": "Maintenance de base du site web",
  "Easy credit-card payments": "Paiements faciles par carte de crédit",
  "Pause or cancel anytime": "Mettez en pause ou annulez à tout moment",

  "Learn more about how DigitechJoy works and how it can help you.":
    "En savoir plus sur le fonctionnement de DigitechJoy et comment il peut vous aider.",

  "Refer a friend & earn": "Parrainez un ami et gagnez",
  "Earn 5% monthly recurring commissions for each referral.":
    "Gagnez 5% de commissions récurrentes mensuelles pour chaque parrainage.",
  "Join now": "Rejoignez maintenant",

  Must: "Doit",
  "Server Hosting Servcie": "Service d'hébergement de serveur",
  "For customers who do not require our development services, we offer separate Server Hosting Servcie. This fee ensures that your website runs stably on the Internet.":
    "Pour les clients qui n'ont pas besoin de nos services de développement, nous proposons un service d'hébergement de serveur séparé. Ce tarif garantit que votre site web fonctionne de manière stable sur Internet.",
  "€15/m": "€15/m",

  // FAQs
  FAQs: "FAQ",

  // FooterBlock
  "See if DigitechJoy is right for you. (It totally is.)":
    "Voyez si DigitechJoy vous convient. (C'est totalement le cas.)",
  "Get a guided tour through Designjoy, and find out how you and your team can change the way you source design, forever.":
    "Faites une visite guidée à travers Designjoy, et découvrez comment vous et votre équipe pouvez changer votre manière de trouver des conceptions, pour toujours.",

  "DigitechJoy is headquartered in Paris, France.":
    "DigitechJoy a son siège à Paris, France.",

  Pricing: "Tarification",
  Contact: "Contact",
  "Client login": "Connexion client",
  "Terms & conditions": "Conditions générales",
  "Privacy policy": "Politique de confidentialité",

  // Buttons
  "Get started": "Commencer",
  "See plans": "Voir les plans",
};

const zh = {
  // Navbars
  Benefits: "会员好处",
  "Scope of work": "工作范围",
  Login: "登录",

  // SectionHeroBlock
  "A Digital Agency with a Unique Approach":
    "一家拥有独特方法的数字解决方案机构",
  "Subscriptions for All. Pause or Cancel Anytime.":
    "适合所有人的网站开发和数字解决方案订阅服务。随时暂停或取消。",
  guaranteeBlock: `<div>你可以信赖</div>
  &nbsp;
  <img
    src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-6-heart.svg"
    loading="lazy"
    alt=""
    class="guarnatee__heart"
  />
  &nbsp;
  <div>的解决方案，保证满意</div>`,

  // PromiseBlock
  "We crafted digital experiences, not just websites.":
    "不仅是建设网站，我们在打造数字化体验",
  "The old way of web development is gone. Welcome to tailored, digital solutions that meet your specific needs.":
    "传统的网站开发方式已成过去。欢迎来到根据您具体需求量身定制的数字解决方案。",
  "Subscribe to a plan & request as many web development or digital solution services as you'd like.":
    "订阅一个计划，并根据需要请求尽可能多的网站开发或数字解决服务。",
  "Receive your solutions within a few business days on average, Monday to Friday.":
    "通常在工作日（周一至周五）内，您可以在几个工作日内收到解决方案。",
  "We'll refine the solutions until you're 100% satisfied.":
    "我们将不断完善解决方案，直到您 100%满意。",
  "DigitechJoy demonstrates expertise in the digital realm.":
    "DigitechJoy 在数字领域展示了专业能力。",

  // advantageBlock
  advantageBlock_1: '这将是"你<span class="never">再也</span>回不去"的体验',
  "DigitechJoy replaces inconsistent freelancers and costly agencies with one flat monthly fee, delivering solutions so efficiently, it will impress you.":
    "Digitechjoy 用一个固定的月费替代了不稳定的自由职业者和昂贵的代理机构，高效地提供解决方案，这将令你印象深刻。",
  "Digital excellence is key, and DigitechJoy has mastered it.":
    "数字化卓越是关键，DigitechJoy 已经掌握了这点。",

  "Totally async": "完全异步",
  "Don't like meetings? We don't either; so much so that we've outlawed them completely.":
    "不喜欢不必要的会议？我们也是。这就是为什么我们计划完全消除它们。",
  "Manage with Notion": "使用 Notion 管理",
  "Manage your website board using Notion. View active, queued and completed tasks with ease.":
    "通过 Notion 跟踪您的项目。轻松查看活跃、排队和完成的任务。",
  "Invite unlimited team members": "邀请团队成员数量不限",
  "Invite your entire team, so anyone can submit requests and track their progress.":
    "您的整个团队都可以参与，提交请求，并跟踪进度。",

  // Membership benefits
  "Membership benefits": "会员好处",
  "Perks so good you'll never need to go anywhere else for your web and digital solutions. Seriously.":
    "方案优势好到你再也不需要为你的网页和数字解决方案而去其他地方。真的。",

  "Design board": "项目板",
  "Add as many digitaltech requests to your board as you'd like":
    "在您的项目板上添加尽可能多的网页和数字解决方案请求。",
  "Lightning fast delivery": "快速交付",
  "Get your digitalsolution one at a time in just a few days on average.":
    "通常几天内逐一交付您的解决方案。",
  "Fixed monthly rate": "固定月费",
  "No surprises here! Pay the same fixed price each month.":
    "没有惊喜！每个月都是一样的价格。",
  "Top-notch quality": "卓越品质",
  "Outstanding digital solutions at your fingertips whenever you need them.":
    "无论何时需要，都能轻松获得出色的数字解决方案。",
  "Flexible and Scalable": "灵活可扩展",
  "Scale up or down as needed, and pause or cancel at anytime.":
    "根据您的需求调整订阅，可随时暂停或取消。",
  "Unique and all yours": "为您量身定制",
  "Each of your digisolution is made especially for you and is 100% yours.":
    "每个解决方案都是为您专门制作的, 100% 完全属于您。",

  // RecentWorkBlock
  "Recent work": "最新作品",
  "Award winning digisolution, and nothing less.":
    "获奖的数字解决方案，绝不妥协。",
  "View recent work": "查看最新作品",

  // ServicesListBlock
  "Websites, applications, digitalization & more.":
    "网站、应用程序、数字化等更多服务",

  // ServicesListBlock - 1. WordPress Website Development
  "WordPress Website Development": "WordPress 网站开发",
  "Custom WordPress website design and development, suitable for various business needs.":
    "定制 WordPress 网站设计和开发，适合各种商业需求。",
  "Includes responsive design to ensure the website performs well on all devices.":
    "包括响应式设计，确保网站在所有设备上均表现良好。",
  // ServicesListBlock - 2. Performance Optimization
  "Performance Optimization": "性能优化",
  "Website performance analysis and optimization to improve loading speed and user experience.":
    "网站性能分析和优化，提高加载速度和用户体验。",
  "Basic SEO optimization to enhance website ranking in search engines.":
    "SEO 基础优化，提升网站在搜索引擎中的排名。",
  // ServicesListBlock - 3. Technical Training and Support
  "Technical Training and Support": "技术培训与支持",
  "Provides training on WordPress and related technologies.":
    "提供 WordPress 和相关技术的培训。",
  "Teaches clients how to independently manage and update their websites.":
    "教授客户如何自主管理和更新他们的网站。",
  // ServicesListBlock - 4. Maintenance and Support
  "Maintenance and Support": "网站维护与支持",
  "Provides regular maintenance for websites, including updates, backups, security monitoring, etc.":
    "提供网站的定期维护，包括更新、备份、安全监控等。",
  "Quick response to solve operational issues with websites.":
    "快速响应解决网站运行中的问题。",
  // ServicesListBlock - 5. Digital Solution Consulting
  "Digital Solution Consulting": "数字化解决方案咨询",
  "Assists businesses in assessing and optimizing their digital strategies.":
    "协助商家评估和优化他们的数字化策略。",
  "Offers customized technical solutions to enhance business efficiency and online performance.":
    "提供定制的技术方案以提升业务效率和在线表现。",

  // PricingBlock
  "Choose a plan that works for you": "选择适合您的方案",
  "No contracts or surprises. Cancel anytime.": "无合同或意外费用。随时取消。",
  "MOST POPULAR": "最受欢迎",

  "Start today": "今天就开始",
  "Book a call": "预约电话沟通",
  "What's included": "包括",

  Standard: "标准方案",
  "For professionals, startups, and enterprises with ongoing website update needs.":
    "适用于需要持续更新网站的专业人士、初创企业和大型企业。",
  "€499/m": "€499/月",
  "One project at a time": "一次进行一个项目",
  "Front-end development with Wordpress": "使用 WordPress 进行网站开发",

  Pro: "专业方案",
  "For those in need of design and front-end development.":
    "适用于需要设计和前端开发的人士。",
  "€999/m": "€999/月",
  "Two projects at a time": "同时进行两个项目",
  "Development and design with WordPress": "提供 WordPress 网站设计和开发",

  "Unlimited requests": "无限请求",
  "Unlimited revisions": "无限次修订",
  "Unlimited team members": "无限团队成员",
  "Average one week delivery (with existing design drafts)":
    "平均一个星期内交付(已有设计稿)",
  "Basic website maintenance": "基本网站维护",
  "Easy credit-card payments": "简单信用卡支付",
  "Pause or cancel anytime": "随时暂停或取消",

  "Learn more about how DigitechJoy works and how it can help you.":
    "了解更多关于 DigitechJoy 如何运作以及它如何能帮助您。",

  "Refer a friend & earn": "推荐朋友 & 赚取收益",
  "Earn 5% monthly recurring commissions for each referral.":
    "每次推荐成功， 您可以获得5%的每月持续佣金。",
  "Join now": "立即加入",

  Must: "必须",
  "Server Hosting Servcie": "服务器托管服务",
  "For customers who do not require our development services, we offer separate Server Hosting Servcie. This fee ensures that your website runs stably on the Internet.":
    "对于不需要我们开发服务的客户，我们提供单独的服务器托管服务。此费用包括基本的网站维护，确保您的网站在互联网上稳定运行。",
  "€15/m": "€15/月",

  // FAQs
  FAQs: "常见问题解答",

  // FooterBlock
  "See if DigitechJoy is right for you. (It totally is.)":
    "看看 DigitechJoy 是否适合您。（绝对适合）",
  "Get a guided tour through Designjoy, and find out how you and your team can change the way you source design, forever.":
    "通过 DigitechJoy 导览，看看我们如何改变数字化解决采购的方式。",

  "DigitechJoy is headquartered in Paris, France.":
    "DigitechJoy 总部位于法国巴黎。",

  Pricing: "价格",
  Contact: "联系",
  "Client login": "客户登录",
  "Terms & conditions": "条款 & 条件",
  "Privacy policy": "隐私政策",

  // Buttons
  "Get started": "开始",
  "See plans": "查看方案",
};

const localeFile = {
  zh,
  fr,
  en,
};

export default localeFile;
