import { COMPANY_NAME } from "../contant";
import { ELocale } from "../enum/ELocale";
import { IFAQ } from "../interface/IFAQ";

export const CONSTANT_FAQ_SET: {
  [ELocale.ZH]: {
    original?: {
      v1: IFAQ[];
      v2: IFAQ[];
    };
    v1: IFAQ[];
  };
  [ELocale.FR]: {
    original?: {
      v1: IFAQ[];
      v2: IFAQ[];
    };
    v1: IFAQ[];
  };
  [ELocale.EN]: {
    original?: {
      v1: IFAQ[];
      v2: IFAQ[];
    };
    v1: IFAQ[];
  };
} = {
  [ELocale.ZH]: {
    // original: {
    //   v1: [
    //     {
    //       question: "真的是无限请求吗？",
    //       answer:
    //         "是的！一旦订阅，您可以根据需要在队列中添加尽可能多的设计请求，这些请求将逐一完成。",
    //     },
    //     {
    //       question: "我多久能收到我的设计？",
    //       answer:
    //         "平均来说，大多数请求在两天或更短时间内完成。但是，更复杂的请求可能需要更长的时间。",
    //     },
    //     {
    //       question: "设计师是谁？",
    //       answer: "Designjoy 是一家独立公司，这意味着您将直接与创始人合作。",
    //     },
    //     {
    //       question: "您使用什么程序进行设计？",
    //       answer: "大多数请求使用 Figma 设计。",
    //     },
    //     {
    //       question: "我如何请求设计？",
    //       answer:
    //         "Designjoy 在如何使用 Trello 请求设计方面提供了很大的灵活性。客户请求设计的一些常见方式包括直接通过 Trello，分享 Google 文档或线框图，甚至录制一个简短的 Loom 视频（对于那些不愿意写出描述文档的人）。基本上，如果它可以链接到或在 Trello 中共享，就可以。",
    //     },
    //     {
    //       question: "如果我不喜欢设计怎么办？",
    //       answer: "没关系！我们将继续修改设计，直到您百分之百满意。",
    //     },
    //     {
    //       question: "如果我只有一个请求怎么办？",
    //       answer:
    //         "没问题。您可以在完成后暂停您的订阅，并在有更多设计需求时返回。没有必要浪费您剩余的订阅时间。",
    //     },
    //     {
    //       question: "使用您的服务有合同吗？",
    //       answer: "使用我们的服务没有合同或承诺。您可以随时取消。",
    //     },
    //   ],
    //   v2: [
    //     {
    //       question: "为什么我不直接雇佣一个全职设计师呢？",
    //       answer: `这是一个好问题！首先，现在一个全职高级设计师的年薪已超过10万美元，外加福利（而且找到一个有空的还真不容易）。除此之外，你可能并不总是有足够的工作让他们忙碌，所以你最终为你无法使用的时间付费。

    //       使用月计划，你可以根据需要暂停和恢复你的订阅，以确保你只在有工作给他们时支付设计师的费用。`,
    //     },
    //     {
    //       question: "我能提交多少设计请求？",
    //       answer:
    //         "一旦订阅，你可以根据需要添加尽可能多的设计请求到你的队列中，他们将逐一被处理。",
    //     },
    //     {
    //       question: "我会多快收到我的设计？",
    //       answer:
    //         "通常，大多数请求在两天或更短的时间内完成。然而，更复杂的请求可能需要更长时间。",
    //     },
    //     {
    //       question: "设计师是谁？",
    //       answer:
    //         "你可能会惊讶地听到这个，但Designjoy实际上是一个人的机构。这意味着你将直接与我合作，Designjoy的创始人。然而，像动画或定制插图这样的增强请求是由合作伙伴设计师提供的。",
    //     },
    //     {
    //       question: "暂停功能是如何工作的？",
    //       answer: `我们明白你可能没有足够的设计工作来填满整个月。也许你现在只有一两个设计请求。这时暂停你的订阅就派上用场了。

    //       计费周期基于31天期限。比方说你注册并使用服务21天，然后决定暂停你的订阅。这意味着计费周期将被暂停，你将有剩余的10天服务随时可用。`,
    //     },
    //     {
    //       question: "你在哪些软件中设计？",
    //       answer: "大多数请求是使用Figma设计的。",
    //     },
    //     {
    //       question: "我如何提交设计请求？",
    //       answer:
    //         "Designjoy在使用Trello提交设计请求方面提供了大量灵活性。客户提交设计的一些常见方式包括直接通过Trello、共享Google文档或线框图，甚至录制简短的Loom视频（对于那些不愿意写描述文档的人）。基本上，如果它可以链接到或在Trello中共享，那就可以。",
    //     },
    //     {
    //       question: "如果我不喜欢设计怎么办？",
    //       answer: "不用担心！我们将继续修改设计，直到你100%满意。",
    //     },
    //     {
    //       question: "有哪些设计工作你们不涵盖？",
    //       answer:
    //         "当然有。Designjoy不包括以下设计工作：3D建模、动画图形（GIF等）、文档设计（医疗表格等）、复杂包装，以及广泛的印刷设计（杂志、书籍等）。",
    //     },
    //     {
    //       question: "如果我只有一个请求怎么办？",
    //       answer:
    //         "没问题。你可以在完成后暂停你的订阅，并在你有更多设计需求时返回。没有必要让你剩余的订阅时间浪费掉。",
    //     },
    //     {
    //       question: "如果我不喜欢服务，有退款吗？",
    //       answer: "由于工作的高质量性质，将不会发放退款。",
    //     },
    //   ],
    // },
    v1: [
      {
        question: "我为什么不直接雇佣一名全职的网页开发人员呢",
        answer: `这是一个非常好的问题。首先，要知道雇佣一名全职的高级网页开发人员可能非常昂贵。目前，这样一位专业人员的年薪可能超过100,000美元，再加上他们的福利待遇，总成本会更高。而且，找到一位合适的、有空闲的高级开发人员也不是件容易的事。此外，可能有时候您的网站并不需要持续的大量工作，这时您就会发现自己在为开发人员闲置的时间付费。<br /><br />
          采用我们的月度计划，您就能根据实际需要灵活地暂停或恢复服务。这样，您只需在有具体开发需求时支付费用。这种方式不仅节省成本，还提供了更大的灵活性，使您能够根据项目的实际需要来调整资源。<br /><br />
          总之，我们的服务旨在为您提供按需支付、高效灵活的网页开发解决方案。`,
        isExtended: false,
      },
      {
        question: "真的是无限请求吗？",
        answer: `是的！一旦订阅，您可以根据需要向您的队列中添加尽可能多的网页开发或数字解决方案请求，这些请求将会逐一得到处理。`,
        isExtended: false,
      },
      {
        question: "我需要多长时间才能看到我请求的网站创建或更新完成？",
        answer: `对于您已有的网站，如果您只需要一些小的改动或更新，我们通常能在两天或更短的时间内完成这些工作。但如果您的要求比较复杂，可能就需要更多的时间。如果您想要从零开始建立一个全新的网站，这通常需要大约一周的时间来完成。`,
        isExtended: false,
      },
      {
        question: "网站的开发工作由谁来完成?",
        answer: `也许你会感到惊讶，${COMPANY_NAME} 实际上是一个人的公司。这意味着你将直接与我一起合作，即 ${COMPANY_NAME} 的创始人。对于一些特定的网页设计需求，如网页布局和视觉效果，我将与专业的合作设计师合作来确保最佳的服务。这样一来，无论是技术开发还是设计创意，你都能获得专业和个性化的支持。`,
        isExtended: false,
      },
      {
        question: "您使用哪些工具和技术？",
        answer: `大部分网站开发请求都是使用 Wordpress 进行开发。`,
        isExtended: false,
      },
      {
        question: "如何提交网站开发请求？",
        answer: `${COMPANY_NAME} 提供了大量灵活的方式，让您可以使用 Notion 申请网站开发。客户请求网站开发的一些常见方式包括直接通过 Notion、共享 Google 文档 或 Figma 线框图，甚至录制简短的 Loom 视频（适用于那些不愿意写出描述说明的客户）。
          <br /><br />
          基本上，只要能链接到 Notion 或在 Notion 中共享，就可以使用。这样，即使您不精通技术，也可以轻松地与我们交流您的需求。`,
        isExtended: false,
      },
      {
        question: "如果我对解决方案不满意怎么办？",
        answer: `放心！我们会不断修改网站或解决方案，直到您 100% 满意为止。`,
        isExtended: false,
      },
      {
        question: "有哪些数字化工作是你不提供的吗？",
        answer: `是的，${COMPANY_NAME} 的专长主要集中在WordPress网站开发、维护、性能优化以及数字化咨询服务。但有些更复杂的数字化项目并不在我们的服务范围内。比如，我们不提供大型软件开发、移动应用程序开发、大规模的图形设计项目、复杂的电子商务解决方案、全面的SEO策略实施以及广泛的数字营销活动。<br /><br />我们专注于我们擅长的领域，以确保为客户提供最高质量的服务。如果您需要这些复杂服务的协助，我们建议您寻找专门在这些领域有丰富经验的其他专业服务提供商。`,
        isExtended: false,
      },
      {
        question: "如果我只有一个项目请求怎么办",
        answer: `没问题。您可以在项目完成后暂停您的服务订阅，并在有其他开发需求时再重新开始。没有必要让您剩余的服务订阅时间浪费掉。`,
        isExtended: false,
      },
      {
        question: '"暂停服务订阅" 如何运作？',
        answer: `我们理解您可能没有足够的网站开发或数字化解决方案工作来填满整个月。也许您目前只有一两个网站开发请求。这时，暂停服务订购就派上用场了。
          <br /><br />
          计费周期以 31 天为单位。假设您注册并使用了 21 天服务，然后决定暂停订阅。这意味着计费周期将暂停，您将有 10 天的剩余服务，可在未来随时使用。`,
        isExtended: false,
      },
      {
        question: "如果我不喜欢这项服务，可以退款吗？",
        answer: `由于工作的高质量要求和结果，我们不提供退款服务。`,
        isExtended: false,
      },
    ],
  },
  [ELocale.EN]: {
    v1: [
      {
        question: "Why not hire a full-time web developer?",
        answer:
          "Good question! First, the annual cost of hiring a full-time senior WordPress web developer now exceeds $100,000, plus benefits (and good luck finding one available). Aside from that, you may not always have enough work to keep them busy, which means you might pay for time you can't use. <br /><br />With our monthly plan, you can pause and resume your subscription as needed, ensuring you only pay for developer time when there is work for them. <br /><br />In summary, our service aims to provide you with a pay-as-you-go, efficient, and flexible web development solution.",
        isExtended: false,
      },
      {
        question: "Is there a limit to the number of requests I can make?",
        answer:
          "Yes! Once subscribed, you're able to add as many web development or digital solution requests to your queue as you'd like, and these requests will be delivered one by one.",
        isExtended: false,
      },
      {
        question: "How fast will I receive my new or updated digisolutions?",
        answer:
          "For existing websites, if you only need some minor changes or updates, on average we can usually complete these tasks in two days or less. However, more complex requirements might take longer. <br /><br />If you are looking to build a brand-new website from scratch, this typically takes about a week to complete.",
        isExtended: false,
      },
      {
        question: "Who will be working on my website?",
        answer: `You might be surprised to learn that ${COMPANY_NAME} is actually a one-person company. This means that you'll be working directly with me, the founder of ${COMPANY_NAME}. For specific web design needs, such as webpage layouts and visual effects, I collaborate with partner designers to ensure the best service.`,
        isExtended: false,
      },
      {
        question: "Which tools and technologies do you use?",
        answer:
          "Most of our web development requests are developed using Wordpress.",
        isExtended: false,
      },
      {
        question: "How do I request the web development?",
        answer: `${COMPANY_NAME} offers a ton of flexibility in how you request web developments using Notion. Some common ways clients request web developments is directly via Notion, sharing Google docs or wireframes, or even recording a brief Loom video (for those who prefer not to write their briefs out). <br /><br />Basically, if it can be linked to or shared in Notion, it's fair game. In this way, even if you are not skilled in technology, you can easily communicate your needs with us.`,
        isExtended: false,
      },
      {
        question: "What if I'm not satisfied with the solution?",
        answer:
          "Don't worry! We will continue to modify the website or solution until you are 100% satisfied.",
        isExtended: false,
      },
      {
        question: "Are there any digital works that you don't provide?",
        answer: `Yes, ${COMPANY_NAME}'s expertise mainly focuses on WordPress website development, maintenance, performance optimization, and digital consulting services. However, we do not provide services for more complex digital projects such as large-scale software development, mobile app development, extensive graphic design projects, complex e-commerce solutions, comprehensive SEO strategy implementation, and broad digital marketing campaigns. <br /><br />We focus on the areas we excel in to ensure the highest quality of service for our clients. If you need assistance with these complex services, we recommend seeking other professional service providers who have extensive experience in these areas.`,
        isExtended: false,
      },
      {
        question: "What if I only have one project request?",
        answer:
          "No problem. You can pause your service subscription after your project is completed and resume it when you have other development needs. There's no need to let any remaining subscription time go to waste.",
        isExtended: false,
      },
      {
        question: 'How does "pause service subscription" work?',
        answer:
          "We understand that you may not have enough web development or digital solution work to fill an entire month. Perhaps you currently have only one or two web development requests. That's where pausing your service subscription comes in handy. <br /><br />Billing cycles are based on 31 days. Suppose you sign up and use the service for 21 days, then decide to pause your subscription. This means your billing cycle will pause, and you'll have 10 days of remaining service that you can use at any time in the future.",
        isExtended: false,
      },
      {
        question: "Can I get a refund if I don't like the service?",
        answer:
          "Due to the high quality of our work and results, we do not offer refunds.",
        isExtended: false,
      },
    ],
  },
  [ELocale.FR]: {
    v1: [
      {
        question:
          "Pourquoi ne pas embaucher un développeur web à temps plein ?",
        answer:
          "Bonne question ! D'abord, le coût annuel pour embaucher un développeur web WordPress senior dépasse maintenant 100 000 $, plus les avantages (et bonne chance pour en trouver un disponible). De plus, vous n'avez peut-être pas toujours assez de travail pour les occuper, ce qui signifie que vous pourriez payer pour du temps que vous ne pouvez pas utiliser. <br /><br />Avec notre plan mensuel, vous pouvez mettre en pause et reprendre votre abonnement selon vos besoins, en veillant à ne payer que pour le temps de développeur lorsque vous avez du travail pour eux. <br /><br />En résumé, notre service vise à vous fournir une solution de développement web efficace et flexible à la demande.",
        isExtended: false,
      },
      {
        question:
          "Y a-t-il une limite au nombre de demandes que je peux faire ?",
        answer:
          "Oui ! Une fois abonné, vous pouvez ajouter autant de demandes de développement web ou de solutions numériques à votre file d'attente que vous le souhaitez, et ces demandes seront traitées une par une.",
        isExtended: false,
      },
      {
        question:
          "Quelle est la rapidité de réception de mes nouvelles solutions ou mises à jour numériques ?",
        answer:
          "Pour les sites web existants, si vous avez besoin seulement de quelques modifications mineures ou mises à jour, nous pouvons généralement accomplir ces tâches en deux jours ou moins en moyenne. Cependant, des exigences plus complexes pourraient prendre plus de temps. <br /><br />Si vous cherchez à construire un tout nouveau site web à partir de zéro, cela prend généralement environ une semaine pour être complété.",
        isExtended: false,
      },
      {
        question: "Qui travaillera sur mon site web ?",
        answer: `Vous serez peut-être surpris d'apprendre que ${COMPANY_NAME} est en fait une entreprise individuelle. Cela signifie que vous travaillerez directement avec moi, le fondateur de ${COMPANY_NAME}. Pour des besoins spécifiques en conception web, tels que les agencements de pages web et les effets visuels, je collabore avec des designers partenaires pour assurer le meilleur service.`,
        isExtended: false,
      },
      {
        question: "Quels outils et technologies utilisez-vous ?",
        answer:
          "La plupart de nos demandes de développement web sont développées en utilisant Wordpress.",
        isExtended: false,
      },
      {
        question: "Comment puis-je demander le développement web ?",
        answer: `${COMPANY_NAME} offre beaucoup de flexibilité sur la manière de demander des développements web en utilisant Notion. Certaines des manières courantes pour les clients de demander des développements web sont directement via Notion, en partageant des documents Google ou des wireframes, ou même en enregistrant une brève vidéo Loom (pour ceux qui préfèrent ne pas écrire leurs briefs). <br /><br />En gros, si cela peut être lié ou partagé dans Notion, c'est possible. De cette manière, même si vous n'êtes pas compétent en technologie, vous pouvez facilement communiquer vos besoins avec nous.`,
        isExtended: false,
      },
      {
        question: "Que faire si je ne suis pas satisfait de la solution ?",
        answer:
          "Ne vous inquiétez pas ! Nous continuerons à modifier le site web ou la solution jusqu'à ce que vous soyez 100% satisfait.",
        isExtended: false,
      },
      {
        question:
          "Y a-t-il des travaux numériques que vous ne fournissez pas ?",
        answer: `Oui, l'expertise de ${COMPANY_NAME} se concentre principalement sur le développement, la maintenance et l'optimisation des performances des sites Web WordPress, ainsi que sur les services de conseil numérique. Cependant, nous ne fournissons pas de services pour des projets numériques plus complexes tels que le développement de logiciels à grande échelle, le développement d'applications mobiles, des projets de conception graphique étendus, des solutions de e-commerce complexes, la mise en œuvre de stratégies SEO complètes et des campagnes de marketing numérique étendues. <br /><br />Nous nous concentrons sur les domaines dans lesquels nous excellons pour assurer la plus haute qualité de service à nos clients. Si vous avez besoin d'assistance pour ces services complexes, nous vous recommandons de chercher d'autres prestataires professionnels ayant une expérience étendue dans ces domaines.`,
        isExtended: false,
      },
      {
        question:
          "Que se passe-t-il si je n'ai qu'une seule demande de projet ?",
        answer:
          "Pas de problème. Vous pouvez mettre en pause votre abonnement après l'achèvement de votre projet et le reprendre lorsque vous avez d'autres besoins de développement. Il n'est pas nécessaire de laisser passer le temps restant de votre abonnement.",
        isExtended: false,
      },
      {
        question: 'Comment fonctionne la "mise en pause de l\'abonnement" ?',
        answer:
          "Nous comprenons que vous n'ayez pas assez de travail de développement web ou de solution numérique pour remplir un mois entier. Peut-être avez-vous actuellement seulement une ou deux demandes de développement web. C'est là que la mise en pause de votre abonnement est utile. <br /><br />Les cycles de facturation sont basés sur 31 jours. Supposons que vous vous inscriviez et utilisiez le service pendant 21 jours, puis décidiez de mettre en pause votre abonnement. Cela signifie que votre cycle de facturation sera interrompu, et vous aurez 10 jours de service restant que vous pourrez utiliser à tout moment dans le futur.",
        isExtended: false,
      },
      {
        question:
          "Puis-je obtenir un remboursement si je n'aime pas le service ?",
        answer:
          "En raison de la haute qualité de notre travail et de nos résultats, nous n'offrons pas de remboursements.",
        isExtended: false,
      },
    ],
  },
};
