import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import SingleHomePageProject from "./HomePage/SingleHomePageProject";
import BEN_CAO_TANG_PAGE from "./follow-paris/ben-cao-tang";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<SingleHomePageProject />} />
        <Route path="*" element={<NoMatch />} />
      </Route>

      <Route path="followparis" element={<Layout />}>
        <Route path="bencaotang" element={<BEN_CAO_TANG_PAGE />} />
        <Route path="*" element={<NoMatch />} />
      </Route>

      <Route path="followparis-bencaotang" element={<BEN_CAO_TANG_PAGE />} />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

function Layout() {
  return <Outlet />;
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
    </div>
  );
}
