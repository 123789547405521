import * as React from "react";
import "../styles/tailwind.css";

import { BrowserRouter } from "react-router-dom";

// import reactLogo from "./../assets/img/react_logo.svg";
import { AppRoutes } from "./Routes";

// const App = () => (
//   <div className="app">
//     <h1>Hello World!</h1>
//     <h1 className="text-primary text-4xl font-bold">
//       Hello world! I am using React
//     </h1>
//     <p>Foo to the barz</p>
//     <img src={reactLogo} height="480" />
//   </div>
// );

const App = () => (
  <div className="app">
    {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}

    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </div>
);

export default App;
