/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */

import "./index.scss";

import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import Lottie from "lottie-react";

import animation_1 from "../../assets/animation/animation_1.json";
import animation_2 from "../../assets/animation/animation_2.json";
import animation_3 from "../../assets/animation/animation_3.json";
import animation_04_benefits_01 from "../../assets/animation/animation_04_benefits_01.json";
import animation_05_benefits_02 from "../../assets/animation/animation_05_benefits_02.json";
import animation_06_benefits_03 from "../../assets/animation/animation_06_benefits_03.json";
import animation_07_benefits_04 from "../../assets/animation/animation_07_benefits_04.json";
import animation_08_benefits_05 from "../../assets/animation/animation_08_benefits_05.json";
import animation_09_benefits_06 from "../../assets/animation/animation_09_benefits_06.json";

import localeSet from "../../locale/locale";
import { IFAQ } from "../../interface/IFAQ";
import { ELocale } from "../../enum/ELocale";
import { CONSTANT_FAQ_SET } from "../../locale/fqaLocale";

const style_fontWeightBold = { fontWeight: "bold" };
const style_hidden = { display: "none" };

const SingleHomePageProject = () => {
  const [localeCookies, setLocaleCookies] = useCookies(["locale"]);
  const [locale, setLocale] = useState<ELocale>(
    localeCookies["locale"] || ELocale.EN,
  );

  const t = (key: string) =>
    localeSet[locale][key] ? localeSet[locale][key] : key;

  useEffect(() => {
    setLocaleCookies("locale", locale);
  }, [locale]);

  return (
    <>
      <div className="project-demo-0-digitechjoy">
        <LogoBlock locale={locale} setLocale={setLocale} />
        <LogoNavBlock t={t} locale={locale} setLocale={setLocale} />
        <SectionHeroBlock t={t} />
        <HorizontalScrollingBannerBlock />
        <PromiseBlock t={t} />
        <DividingSectionBlock_1 />
        <AdvantageBlock t={t} />
        <DividingSectionBlock_2 />
        <BenefitsBlock t={t} />
        <DividingSectionBlock_3 />
        <RecentWorkBlock t={t} />
        <DividingSectionBlock_4 />
        {/* <SectionOtherProjectsBlock_not_used /> */}
        <ServicesListBlock t={t} />
        <DividingSectionBlock_5 />
        <PricingBlock t={t} />
        <DividingSectionBlock_6 />
        <FAQBlock t={t} locale={locale} />
        <FooterBlock t={t} />
        <FloatNavBlock t={t} />
      </div>
      {/* <div className="block_buttons p-4">
        <ReturnButton />
      </div> */}
    </>
  );
};

const FloatNavBlock = ({ t }) => {
  return (
    <div className="floating-nav floatNavBlock">
      <div className="div-block-17">
        <div
          data-animation="default"
          className="navbar-2 w-nav"
          data-easing2="ease"
          data-easing="ease"
          data-collapse="medium"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          // data-w-id="4cd32b4f-d310-d9d4-8bdd-b271842aef9d"
          role="banner"
          data-duration="400"
        >
          <nav role="navigation" className="nav-menu-2 w-nav-menu">
            <a
              href="#hero"
              className="floating-nav__link arrow w-nav-link w--current"
            ></a>
            <a href="#benefits" className="floating-nav__link w-nav-link">
              {t("Benefits")}
            </a>
            <a href="#latest" className="floating-nav__link w-nav-link">
              {t("Recent work")}
            </a>
            <a href="#sow" className="floating-nav__link w-nav-link">
              {t("Scope of work")}
            </a>
            <a href="#pricing-2" className="floating-nav__link w-nav-link">
              {t("Pricing")}
            </a>
            <a href="#faq" className="floating-nav__link w-nav-link">
              {t("FAQs")}
            </a>
            <a
              href="https://billing.stripe.com/p/login/fZe7vv3Lw9Tz3NS144"
              // eslint-disable-next-line react/no-unknown-property
              ms-hide-element="true"
              className="floating-nav__link login w-nav-link"
            >
              {t("Login")}
            </a>
            <a
              href="/log-in"
              // eslint-disable-next-line react/no-unknown-property
              ms-profile="true"
              className="floating-nav__link w-nav-link"
              style={style_hidden}
            >
              My account
            </a>
          </nav>
          <div
            className="w-nav-button"
            // style="-webkit-user-select: text;"
            aria-label="menu"
            role="button"
            // tabindex="0"
            aria-controls="w-nav-overlay-1"
            aria-haspopup="menu"
            aria-expanded="false"
          >
            <div className="w-icon-nav-menu"></div>
          </div>
          <div
            className="w-nav-overlay"
            data-wf-ignore=""
            id="w-nav-overlay-1"
          ></div>
        </div>
      </div>
    </div>
  );
};

const FooterBlock = ({ t }) => {
  return (
    <div className="section footer footerBlock">
      <div className="container">
        <div
          data-w-id="e21e1f8d-f728-e6e2-065d-4466a59029d4"
          className="container__800 m-auto hero"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        >
          <h2 style={style_fontWeightBold}>
            {t("See if DigitechJoy is right for you. (It totally is.)")}
          </h2>
          <p className="sub-para-24">
            {t(
              "Get a guided tour through Designjoy, and find out how you and your team can change the way you source design, forever.",
            )}
          </p>
          <a href="#pricing-3" className="cta-button w-inline-block">
            <div>{t("Get started")}</div>
          </a>
        </div>
        <div
          data-w-id="e21e1f8d-f728-e6e2-065d-4466a59029dd"
          className="logos"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        >
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-7-Frame.svg"
            loading="lazy"
            alt=""
            className="footer__logo lang"
          />
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-8-dark-marque-logo.svg"
            loading="lazy"
            alt=""
            className="footer__logo soundstripe white"
          />
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-9-nectar-sleep-logo.svg"
            loading="lazy"
            alt=""
            className="footer__logo nectar"
          />
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-13-Vector-2.svg"
            loading="lazy"
            alt=""
            className="footer__logo"
          />
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-14-Group-2.svg"
            loading="lazy"
            alt=""
            className="footer__logo soundstripe"
          />
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-15-cometchat-2.svg"
            loading="lazy"
            alt=""
            className="footer__logo"
          />
        </div>
        <div
          data-w-id="e21e1f8d-f728-e6e2-065d-4466a59029e4"
          className="bottom"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        >
          <div className="footer__left-col">
            <a
              href="/"
              aria-current="page"
              className="footer__bottom-logo w-inline-block w--current"
            >
              <img
                src="https://www.xiaokaup.com/digitechJoy/logo-digitechjoy-white.png"
                loading="lazy"
                alt=""
              />
            </a>
            <div className="div-block-3">
              <img
                src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-16-Frame-4.svg"
                loading="lazy"
                alt=""
                className="az"
              />
              <div className="footer__head">
                {t("DigitechJoy is headquartered in Paris, France.")}
              </div>
            </div>
          </div>
          <div className="footer__right-col">
            <div className="footer__link-col-left">
              <a href="#latest" className="footer__link">
                {t("Recent work")}
              </a>
              <a href="#pricing-3" className="footer__link">
                {t("Pricing")}
              </a>
              <a
                href="mailto:jiean.yang.freelancer@gmail.com?subject=DigitechJoy%20Inquiry"
                className="footer__link"
              >
                {t("Contact")}
              </a>
            </div>
            <div>
              <a
                href="https://billing.stripe.com/p/login/fZe7vv3Lw9Tz3NS144"
                className="footer__link"
              >
                {t("Client login")}
              </a>
              <a href="#pricing-3" className="footer__link">
                {t("Get started")}
              </a>
              <a
                // href="https://www.notion.so/brettwill1025/Terms-of-Use-4901d894656448a69c4c4e04d40d3bbc?pvs=4"
                target="_blank"
                className="footer__link"
                style={style_hidden}
              >
                {t("Terms & conditions")}
              </a>
              <a
                href="https://xiaokaup.notion.site/Privacy-Policy-2f147bdb19f240e4960763d93eb90a06?pvs=74"
                target="_blank"
                className="footer__link"
              >
                {t("Privacy policy")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-17-footer.svg"
        loading="lazy"
        alt=""
        className="image-42"
      />
    </div>
  );
};

const FAQBlock = ({ t, locale }) => {
  const [faqSet, setFaqSet] = React.useState<IFAQ[]>(
    CONSTANT_FAQ_SET[locale].v1,
  );

  useEffect(() => {
    setFaqSet(CONSTANT_FAQ_SET[locale].v1);
  }, [locale]);

  return (
    <div id="faq" className="section white p-t-150 faqBlock">
      <div className="container">
        <h2
        // data-w-id="68473092-de01-5c15-12ef-7d075beb4576" style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        >
          {t("FAQs")}
        </h2>
        <div
          // data-w-id="c9b1f105-50eb-7aed-2e96-3ba9d79937c6" style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="faq__list-wrapper"
        >
          {faqSet.map((item: IFAQ, index: number) => {
            const { question, answer, isExtended } = item;

            const triggerClick = () => {
              const newFaqSet = [...faqSet];
              newFaqSet[index].isExtended = !newFaqSet[index].isExtended;
              setFaqSet(newFaqSet);
            };

            return (
              <div key={question} className="faq__row" onClick={triggerClick}>
                <div className="faq__left">
                  <div className="faq__title">{question}</div>
                  <div
                    //  style={{display:"none"}}
                    style={isExtended ? {} : { display: "none" }}
                    className="faq__answer"
                  >
                    <span dangerouslySetInnerHTML={{ __html: answer }} />
                  </div>
                </div>
                <img
                  src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-18-arrow-down.svg"
                  loading="lazy"
                  // style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                  alt=""
                  className={
                    isExtended ? "faq__arrow isExtended" : "faq__arrow"
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const DividingSectionBlock_6 = () => {
  return (
    <div className="dividing-section dividingSectionBlock_6">
      <div className="dividing-section__half tan"></div>
      <div className="dividing-section__half white"></div>
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-19-Divider.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" data-w-id="d08448c0-14dd-e479-7e03-fa084921f5a6"
        alt=""
        className="divider"
      />
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-20-design.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" data-w-id="d08448c0-14dd-e479-7e03-fa084921f5a7"
        alt=""
        className="image-40 _4"
      />
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-21-design-2.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" data-w-id="d08448c0-14dd-e479-7e03-fa084921f5a8"
        alt=""
        className="image-41 _2"
      />
    </div>
  );
};

const PricingBlock = ({ t }) => {
  return (
    <div id="pricing-2" className="section pricing pricingBlock">
      <div className="container">
        <div
          // data-w-id="f768d994-700e-86d6-9f81-2b33264c2d49" style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="container__800 m-auto hero"
        >
          <h2 className="pricing-header">
            {t("Choose a plan that works for you")}
          </h2>
          <p className="sub-para-24">
            {t("No contracts or surprises. Cancel anytime.")}
          </p>
          {/* vesion 2 */}
          {/* <h2 className="pricing-header">Memberships levels</h2>
          <p className="sub-para-24">Choose a plan that's right for you.</p> */}
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-22-design-3.svg"
            loading="lazy"
            alt=""
            className="pricing__squiggle"
          />
        </div>
        <div
          id="pricinggrid"
          // data-w-id="f768d994-700e-86d6-9f81-2b33264c2d4f"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="w-layout-grid pricing__grid"
        >
          <div className="pricing__col">
            <div
              id="pricing-3"
              className="pricing__col-top"
              style={{ paddingTop: "30px" }}
            >
              <div className="pricing__col-top-top">
                <div className="pricing__title" style={style_fontWeightBold}>
                  {t("Standard")}
                </div>
                <div className="pricing__description">
                  {t(
                    "For professionals, startups, and enterprises with ongoing website update needs.",
                  )}
                </div>
              </div>
              <div className="pricing__col-top-bottom">
                <div className="pricing__amount" style={style_fontWeightBold}>
                  {t("€499/m")}
                </div>
                <div className="pricing__not-included m-t-0">
                  {t("Pause or cancel anytime")}
                </div>
                <div className="button__group pricing">
                  <a
                    // data-ms-membership="62558f15e13be400045542ac"
                    href="https://buy.stripe.com/7sI9BK3C0dENdeE5kk"
                    className="button pricing w-inline-block"
                  >
                    <div>{t("Start today")}</div>
                  </a>
                </div>
                <div>
                  <a
                    href="https://calendly.com/jiean-yang-digitech/digitechjoy-intro"
                    target="_blank"
                    className="book-call-wrapper _2"
                  >
                    {t("Book a call")}
                  </a>
                </div>
              </div>
              <div className="most-popular-tag">
                <div>{t("MOST POPULAR")}</div>
              </div>
              <img
                src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-23-design-3.svg"
                loading="lazy"
                alt=""
                className="image-67"
              />
            </div>
            <div className="pricing__col-bottom">
              <div className="pricing__col-bottom-bottom">
                <div className="pricing__included">{t("What's included")}:</div>
                <ul role="list" className="pricing__list">
                  <li className="pricing__li">
                    <div>{t("One project at a time")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Front-end development with Wordpress")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Unlimited requests")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Unlimited revisions")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Unlimited team members")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>
                      {t(
                        "Average one week delivery (with existing design drafts)",
                      )}
                    </div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Basic website maintenance")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Easy credit-card payments")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Pause or cancel anytime")}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="pricing__col">
            <div className="pricing__col-top normal">
              <div className="pricing__col-top-top">
                <div className="pricing__title" style={style_fontWeightBold}>
                  {t("Pro")}
                </div>
                <div className="pricing__description">
                  {t("For those in need of design and front-end development.")}
                </div>
              </div>
              <div className="pricing__col-top-bottom">
                <div className="pricing__amount" style={style_fontWeightBold}>
                  {t("€999/m")}
                </div>
                <div className="pricing__not-included m-t-0">
                  {t("Pause or cancel anytime")}
                </div>
                <div className="button__group pricing">
                  <a
                    // data-ms-membership="62678c0904c1df00043bf6ff"
                    href="https://buy.stripe.com/3csbJS2xW1W5b6w5kl"
                    className="button w-inline-block"
                  >
                    <div>{t("Start today")}</div>
                  </a>
                </div>
                <div className="book-call-wrapper"></div>
                <div className="div-block-18">
                  <a
                    href="https://calendly.com/jiean-yang-digitech/digitechjoy-intro"
                    target="_blank"
                    className="book-call-wrapper _2"
                  >
                    {t("Book a call")}
                  </a>
                </div>
              </div>
            </div>
            <div className="pricing__col-bottom">
              <div className="pricing__col-bottom-bottom">
                <div className="pricing__included">{t("What's included")}:</div>
                <ul role="list" className="pricing__list">
                  <li className="pricing__li">
                    <div>
                      <strong>{t("Two projects at a time")}</strong>
                    </div>
                  </li>
                  <li className="pricing__li">
                    <div>
                      <strong>
                        {t("Development and design with WordPress")}
                      </strong>
                    </div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Unlimited requests")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Unlimited revisions")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Unlimited team members")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>
                      {t(
                        "Average one week delivery (with existing design drafts)",
                      )}
                    </div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Basic website maintenance")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Easy credit-card payments")}</div>
                  </li>
                  <li className="pricing__li">
                    <div>{t("Pause or cancel anytime")}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="pricing__right-col">
            <div className="pricing__right-col-top">
              <img
                src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-24-design-4.svg"
                loading="lazy"
                alt=""
                className="pricing__right-col-img"
              />
              <div
                className="pricing__title small"
                style={style_fontWeightBold}
              >
                {t("Book a call")}
              </div>
              <div className="pricing__description">
                {t(
                  "Learn more about how DigitechJoy works and how it can help you.",
                )}
              </div>
              <a
                href="https://calendly.com/jiean-yang-digitech/digitechjoy-intro"
                target="_blank"
                className="pricing__text-link"
              >
                {t("Book a call")}
              </a>
            </div>
            <div className="pricing__right-col-top bottom" style={style_hidden}>
              <img
                src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-25-design-5.svg"
                loading="lazy"
                alt=""
                className="pricing__right-col-img"
              />
              <div
                className="pricing__title small"
                style={style_fontWeightBold}
              >
                {t("Refer a friend & earn")}
              </div>
              <div className="pricing__description">
                {t("Earn 5% monthly recurring commissions for each referral.")}
              </div>
              <a
                href="https://xiaokaup.getrewardful.com/signup"
                target="_blank"
                className="pricing__text-link"
              >
                {t("Join now")}
              </a>
            </div>
          </div>
        </div>
        <div className="w-layout-grid grid-2">
          <div
            id="w-node-_579720a1-9250-87b7-64d3-78773a50fabb-1bf5e5de"
            className="webflow"
          >
            <div>
              <div className="badge-pricing-secondary">
                <div>{t("Must")}</div>
              </div>
              <h2 className="power-ups" style={style_fontWeightBold}>
                {t("Server Hosting Servcie")}
              </h2>
              <div className="pricing__description _2">
                {t(
                  "For customers who do not require our development services, we offer separate Server Hosting Servcie. This fee ensures that your website runs stably on the Internet.",
                )}
              </div>
              <h2 className="power-ups price" style={style_fontWeightBold}>
                {t("€15/m")}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DividingSectionBlock_5 = () => {
  return (
    <div className="dividing-section dividingSectionBlock_5">
      <div className="dividing-section__half white"></div>
      <div className="dividing-section__half tan"></div>
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-19-Divider.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" data-w-id="a2a713b2-747a-3521-c62a-cc878ab14dea"
        alt=""
        className="divider"
      />
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-26-design-6.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" data-w-id="c80497eb-2e9c-f564-5e86-b4ac25aa3487"
        alt=""
        className="image-40"
      />
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-27-design-7.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" data-w-id="36447cd7-d0cd-27e6-5e9f-0c51d9ae4dd9"
        alt=""
        className="image-41"
      />
    </div>
  );
};

const ServicesListBlock = ({ t }) => {
  return (
    <div id="sow" className="section white servicesListBlock">
      <div className="container">
        <h2
          style={style_fontWeightBold}
          // data-w-id="92b7ca35-434c-0477-5ac5-31882ca1bd53"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        >
          {t("Websites, applications, digitalization & more.")}
        </h2>
        <div
          // data-w-id="ae4f00de-7ef9-d071-0cef-1cbc4e98a622"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="scope__wrapper"
        >
          <div className="w-layout-grid scope__grid">
            <div
              id="w-node-a476bea0-4d4e-9005-ee25-f88a19146459-1bf5e5de"
              className="scope__col"
            >
              <div className="scope__item">
                <div>{t("WordPress Website Development")}</div>
                <ul>
                  <li>
                    {t(
                      "Custom WordPress website design and development, suitable for various business needs.",
                    )}
                  </li>
                  <li>
                    {t(
                      "Includes responsive design to ensure the website performs well on all devices.",
                    )}
                  </li>
                </ul>
              </div>
              <div className="scope__item">
                <div>{t("Maintenance and Support")}</div>
                <ul>
                  <li>
                    {t(
                      "Provides regular maintenance for websites, including updates, backups, security monitoring, etc.",
                    )}
                  </li>
                  <li>
                    {t(
                      "Quick response to solve operational issues with websites.",
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="scope__col">
              <div className="scope__item">
                <div>{t("Performance Optimization")}</div>
                <ul>
                  <li>
                    {t(
                      "Website performance analysis and optimization to improve loading speed and user experience.",
                    )}
                  </li>
                  <li>
                    {t(
                      "Basic SEO optimization to enhance website ranking in search engines.",
                    )}
                  </li>
                </ul>
              </div>
              <div className="scope__item">
                <div>{t("Digital Solution Consulting")}</div>
                <ul>
                  <li>
                    {t(
                      "Assists businesses in assessing and optimizing their digital strategies.",
                    )}
                  </li>
                  <li>
                    {t(
                      "Offers customized technical solutions to enhance business efficiency and online performance.",
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="scope__col">
              <div className="scope__item">
                <div>{t("Technical Training and Support")}</div>
                <ul>
                  <li>
                    {t(
                      "Provides training on WordPress and related technologies.",
                    )}
                  </li>
                  <li>
                    {t(
                      "Teaches clients how to independently manage and update their websites.",
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-28-design-8.svg"
            loading="lazy"
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            // data-w-id="b4935d71-c7ee-7651-36e0-1b9e11fa7d82"
            alt=""
            className="image-39"
          />
        </div>
      </div>
    </div>
  );
};

const SectionOtherProjectsBlock_not_used = () => {
  return (
    <div className="section white p-b-40 sectionOtherProjectsBlock">
      <div className="container">
        <div
          // data-w-id="69baa197-2781-884b-dabb-fb9e96885e67"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="funky__block"
        >
          <div className="funky__splash">
            <h3 className="heading-2">Funky Scribbbles</h3>
            <div className="funky__description">
              A collection of funky vectorized scribbbles to spice up your
              design projects.
              <a href="https://gumroad.com/l/ttEBH" target="_blank">
                <strong>
                  <br />
                </strong>
              </a>
            </div>
            <div className="button__group">
              <a
                href="https://scribbbles.webflow.io/"
                target="_blank"
                className="button large w-inline-block"
              >
                <div>Check it out</div>
              </a>
            </div>
          </div>
          <div className="funky__logos-flex">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-29-design-9.svg"
              loading="lazy"
              alt=""
              className="funky__logo"
            />
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-30-design-10.svg"
              loading="lazy"
              alt=""
              className="funky__logo"
            />
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-31-design-11.svg"
              loading="lazy"
              alt=""
              className="funky__logo"
            />
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-32-design-12.svg"
              loading="lazy"
              alt=""
              className="funky__logo verizon"
            />
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-33-design-13.svg"
              loading="lazy"
              alt=""
              className="funky__logo chase"
            />
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-34-design-14.svg"
              loading="lazy"
              alt=""
              className="funky__logo"
            />
          </div>
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-35-design-15.svg"
            loading="lazy"
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" data-w-id="f561e381-495e-63a6-70d3-af5bc4766009"
            alt=""
            className="image-38"
          />
        </div>
      </div>
    </div>
  );
};

const DividingSectionBlock_4 = () => {
  return (
    <div className="dividing-section dividingSectionBlock_4">
      <div className="dividing-section__half gray"></div>
      <div className="dividing-section__half white"></div>
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-36-design-16.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" data-w-id="de2cda64-d33b-f25f-69b3-890f5d7e47ca"
        alt=""
        className="divider"
      />
    </div>
  );
};

const RecentWorkBlock = ({ t }) => {
  return (
    <div id="latest" className="section gray recentWorkBlock">
      <div className="container">
        <div
          // data-w-id="df17470f-a56e-c26f-2a70-b7953abdd13b"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="recent-work__title-block"
        >
          <h2 style={style_fontWeightBold}>{t("Recent work")}</h2>
          <p className="sub-para-24">
            {t("Award winning digisolution, and nothing less.")}
          </p>
          <div className="button__group recent-work" style={style_hidden}>
            <a
              href="https://www.figma.com/proto/xMf3kagAMyUSNulcEMNUAU/Sample-Work?page-id=0%3A1&amp;node-id=1%3A2&amp;viewport=252%2C48%2C0.25&amp;scaling=min-zoom&amp;starting-point-node-id=1%3A2"
              target="_blank"
              className="button hero w-inline-block"
            >
              <div>{t("View recent work")}</div>
            </a>
          </div>
        </div>
        <div className="w-layout-grid latest-projects__grid">
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-37-design-17.jpeg"
            alt=""
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            sizes="100vw"
            // data-w-id="4842b25b-1cf5-eef9-a31f-6096121acfbd"
            loading="lazy"
            srcSet="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-38-design-18.jpeg 500w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-37-design-17-p-800.jpeg 800w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-37-design-17-p-1080.jpeg 1080w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-37-design-17-p-1600.jpeg 1600w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-37-design-17.jpeg 2000w"
          />
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-39-design-19.jpeg"
            alt=""
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            sizes="100vw"
            //  data-w-id="f41a03e3-96b5-d499-4203-97cfc0fffe77"
            loading="lazy"
            srcSet="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-40-design-20.jpeg 500w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-39-design-19-p-800.jpeg 800w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-39-design-19-p-1080.jpeg 1080w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-39-design-19-p-1600.jpeg 1600w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-39-design-19.jpeg 2000w"
          />
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-41-design-21.jpeg"
            alt=""
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            sizes="100vw"
            data-w-id="5e756ba2-5fbe-cbad-9cf5-6d50f294f5e4"
            loading="lazy"
            srcSet="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-42-design-22.jpeg 500w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-41-design-21-p-800.jpeg 800w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-41-design-21.jpeg 1001w"
          />
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-43-design-23.jpeg"
            alt=""
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            sizes="100vw"
            data-w-id="4669fb84-43d3-407d-351a-333dc453fdb1"
            id="w-node-_4669fb84-43d3-407d-351a-333dc453fdb1-1bf5e5de"
            loading="lazy"
            srcSet="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-44-design-24.jpeg 500w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-43-design-23-p-800.jpeg 800w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-43-design-23-p-1080.jpeg 1080w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-43-design-23-p-1600.jpeg 1600w, https://www.xiaokaup.com/digitechJoy/2024-03-08-img-43-design-23.jpeg 2000w"
          />
        </div>
        <div
          // data-w-id="83b193b0-1da4-0206-a988-72c0fed31aa5"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="container__800 m-auto logo"
        >
          <div className="recent-work__logos-title">
            Designs commonly featured by
          </div>
          <div className="recent-work__logos-flex">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-45-design-25.svg"
              loading="lazy"
              alt=""
              className="recent-work__logo dribbble"
            />
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-46-design-26.svg"
              loading="lazy"
              alt=""
              className="recent-work__logo"
            />
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-47-design-27.svg"
              loading="lazy"
              alt=""
              className="recent-work__logo awwwards"
            />
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-48-design-28.svg"
              loading="lazy"
              alt=""
              className="recent-work__logo ph"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DividingSectionBlock_3 = () => {
  return (
    <div className="dividing-section dividingSectionBlock_3">
      <div className="dividing-section__half white"></div>
      <div className="dividing-section__half gray"></div>
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-49-design-29.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        // data-w-id="66261d04-d1ec-a300-6c36-1abbe4886862"
        alt=""
        className="divider"
      />
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-50-design-30.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        // data-w-id="ec263296-af7d-8b78-87fa-ee07a970817e"
        alt=""
        className="image-37"
      />
    </div>
  );
};

const BenefitsBlock = ({ t }) => {
  return (
    <div id="benefits" className="section white benefitsBlock">
      <div className="container">
        <div className="benefits__title-block">
          <div className="benefits__splash-wrapper">
            <div
              data-w-id="44020105-95b5-fefc-064f-33c2dd2457ff"
              // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
              className="container__800 m-auto"
            >
              <img
                src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-51-design-31.svg"
                loading="lazy"
                alt=""
                className="benefits__icon"
              />
              <h2 style={style_fontWeightBold}>{t("Membership benefits")}</h2>
              <p className="sub-para-24 max-6700 benefits">
                {t(
                  "Perks so good you'll never need to go anywhere else for your web and digital solutions. Seriously.",
                )}
              </p>
              <div className="button__group">
                <a href="#pricing-3" className="button large w-inline-block">
                  <div>{t("See plans")}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          data-w-id="f8aa1968-ab07-d0f3-28a2-161e8436b6e8"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="w-layout-grid benefits__grid"
        >
          <div
            id="w-node-f8aa1968-ab07-d0f3-28a2-161e8436b6f5-1bf5e5de"
            className="benefits__ind-block"
          >
            <div className="benefits__icon-block">
              <Lottie animationData={animation_04_benefits_01} loop={true} />
            </div>
            <div className="benefits__title">{t("Design board")}</div>
            <div className="benefits__description">
              {t(
                "Add as many digitaltech requests to your board as you'd like",
              )}
            </div>
          </div>
          <div className="benefits__ind-block">
            <div className="benefits__icon-block">
              <Lottie animationData={animation_05_benefits_02} loop={true} />
            </div>
            <div className="benefits__title">
              {t("Lightning fast delivery")}
            </div>
            <div className="benefits__description">
              {t(
                "Get your digitalsolution one at a time in just a few days on average.",
              )}
            </div>
          </div>
          <div className="benefits__ind-block">
            <div className="benefits__icon-block">
              <Lottie animationData={animation_06_benefits_03} loop={true} />
            </div>
            <div className="benefits__title">{t("Fixed monthly rate")}</div>
            <div className="benefits__description">
              {t("No surprises here! Pay the same fixed price each month.")}
            </div>
          </div>
          <div className="benefits__ind-block">
            <div className="benefits__icon-block">
              <Lottie animationData={animation_07_benefits_04} loop={true} />
            </div>
            <div className="benefits__title">{t("Top-notch quality")}</div>
            <div className="benefits__description">
              {t(
                "Outstanding digital solutions at your fingertips whenever you need them.",
              )}
            </div>
          </div>
          <div className="benefits__ind-block">
            <div className="benefits__icon-block">
              <Lottie animationData={animation_08_benefits_05} loop={true} />
            </div>
            <div className="benefits__title">{t("Flexible and Scalable")}</div>
            <div className="benefits__description">
              {t("Scale up or down as needed, and pause or cancel at anytime.")}
            </div>
          </div>
          <div className="benefits__ind-block">
            <div className="benefits__icon-block">
              <Lottie animationData={animation_09_benefits_06} loop={true} />
            </div>
            <div className="benefits__title">{t("Unique and all yours")}</div>
            <div className="benefits__description">
              {t(
                "Each of your digisolution is made especially for you and is 100% yours.",
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DividingSectionBlock_2 = () => {
  return (
    <div className="dividing-section dividingSectionBlock_2">
      <div className="dividing-section__half gray"></div>
      <div className="dividing-section__half white"></div>
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-52-design-32.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        // data-w-id="409eb0c2-8735-eaf6-0ce2-0e88c8ef08d8"
        alt=""
        className="divider"
      />
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-53-design-33.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        // data-w-id="409eb0c2-8735-eaf6-0ce2-0e88c8ef08d9"
        alt=""
        className="image-33"
      />
    </div>
  );
};

const AdvantageBlock = ({ t }) => {
  return (
    <div className="section gray advantageBlock">
      <div className="container">
        <div
          // data-w-id="f52f6028-4d8a-2d65-4653-0db602e5c4a7"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="container__800 m-auto hero"
        >
          <h2
            className="max-600"
            style={style_fontWeightBold}
            dangerouslySetInnerHTML={{ __html: t("advantageBlock_1") }}
          ></h2>
          <p className="sub-para-24 max-6700">
            {t(
              "DigitechJoy replaces inconsistent freelancers and costly agencies with one flat monthly fee, delivering solutions so efficiently, it will impress you.",
            )}
          </p>
        </div>
        <div className="w-layout-grid feature-quote__grid">
          <div
            // data-w-id="82287f70-7923-a6b8-fa13-d1170891a12b"
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            className="feature-quote__quote-block"
          >
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-54-design-34.svg"
              loading="lazy"
              alt=""
              className="m-b-30"
            />
            <div style={style_fontWeightBold}>
              {t("Digital excellence is key, and DigitechJoy has mastered it.")}
            </div>
            <div className="author-wrapper">
              <div className="author-avatar"></div>
              <div className="feature-quote__quote-author">
                Kevin O'Leary,{" "}
                <span className="text-span">
                  🦈 <strong>Shark Tank</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="feature-quote__feature-block">
            <div
              // data-w-id="6d7355a4-37e6-de1e-eaed-a8870fc6a031"
              // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
              className="feature-quote__feature-ind-block"
            >
              <div className="feature-quote__feature-title m-b-10">
                {t("Totally async")}
              </div>
              <div className="feature-quote__description">
                {t(
                  "Don't like meetings? We don't either; so much so that we've outlawed them completely.",
                )}
              </div>
            </div>
            <div
              // data-w-id="16b2d98a-b80a-a7e6-d44b-3b457d00a801"
              // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
              className="feature-quote__feature-ind-block"
            >
              <div className="feature-quote__feature-title m-b-10">
                {t("Manage with Notion")}
              </div>
              <div className="feature-quote__description">
                {t(
                  "Manage your website board using Notion. View active, queued and completed tasks with ease.",
                )}
              </div>
            </div>
            <div
              // data-w-id="e37ca2a1-2d40-5727-6fd1-6d3fee7276f3"
              // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
              className="feature-quote__feature-ind-block last-child"
            >
              <div className="feature-quote__feature-title m-b-10">
                {t("Invite unlimited team members")}
              </div>
              <div className="feature-quote__description">
                {t(
                  "Invite your entire team, so anyone can submit requests and track their progress.",
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DividingSectionBlock_1 = () => {
  return (
    <div className="dividing-section dividingSectionBlock_1">
      <div className="dividing-section__half white">
        <img
          src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-55-design-35.svg"
          loading="lazy"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          // data-w-id="121b54b8-f5ec-f88a-2fb9-18c945b6d464"
          alt=""
          className="image-29"
        />
        <img
          src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-56-design-36.svg"
          loading="lazy"
          //  style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          // data-w-id="121b54b8-f5ec-f88a-2fb9-18c945b6d465"
          alt=""
          className="image-30"
        />
      </div>
      <div className="dividing-section__half gray">
        <img
          src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-49-design-29.svg"
          loading="lazy"
          //  style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          // data-w-id="121b54b8-f5ec-f88a-2fb9-18c945b6d467"
          alt=""
          className="divider"
        />
      </div>
    </div>
  );
};

const PromiseBlock = ({ t }) => {
  return (
    <div className="section white p-t-100 promiseBlock">
      <div className="container">
        <div
          data-w-id="01dafc51-fe61-acbf-1a5a-0ce8e7716534"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="container__800 m-auto hero"
        >
          <h2 className="max-600">
            {t("We crafted digital experiences, not just websites.")}
          </h2>
          <p className="sub-para-24 max-600">
            {t(
              "The old way of web development is gone. Welcome to tailored, digital solutions that meet your specific needs.",
            )}
          </p>
        </div>
        <div
          data-w-id="01dafc51-fe61-acbf-1a5a-0ce8e7716539"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="w-layout-grid how-it-works__grid"
        >
          <div className="div-block-19">
            <div className="process__icon-block">
              <Lottie animationData={animation_1} loop={true} />
            </div>
            <div className="_1">
              {t(
                "Subscribe to a plan & request as many web development or digital solution services as you'd like.",
              )}
            </div>
          </div>
          <div className="div-block-19">
            <div className="process__icon-block receive">
              <Lottie
                animationData={animation_2}
                loop={true}
                style={{ width: 80 }}
              />
            </div>
            <div>
              {t(
                "Receive your solutions within a few business days on average, Monday to Friday.",
              )}
            </div>
          </div>
          <div className="div-block-19">
            <div className="process__icon-block revise">
              <Lottie
                animationData={animation_3}
                loop={true}
                style={{ width: 88 }}
              />
            </div>
            <div>
              {t("We'll refine the solutions until you're 100% satisfied.")}
            </div>
          </div>
        </div>
        <div
          data-w-id="01dafc51-fe61-acbf-1a5a-0ce8e7716549"
          // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="quote"
        >
          <div className="quote__wrapper">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-57-design-37.svg"
              loading="lazy"
              alt=""
              className="quote_sign m-b-30"
            />
            <div className="quote_text">
              {t("DigitechJoy demonstrates expertise in the digital realm.")}
            </div>
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-58-design-38.svg"
              loading="lazy"
              alt=""
              className="image-76"
            />
          </div>
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-59-design-39.svg"
            loading="lazy"
            // style="opacity: 0;"
            data-w-id="01dafc51-fe61-acbf-1a5a-0ce8e771654f"
            alt=""
            className="image-31"
          />
          <img
            src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-60-design-40.svg"
            loading="lazy"
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            // data-w-id="01dafc51-fe61-acbf-1a5a-0ce8e7716550"
            alt=""
            className="image-32"
          />
        </div>
      </div>
    </div>
  );
};

const HorizontalScrollingBannerBlock = () => {
  return (
    <div className="marquee__wrapper horizontalScrollingBannerBlock">
      <div className="marquee__inner">
        <div
          className="marquee__element"
          // style="transform: translate3d(-39.083%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; will-change: transform;"
        >
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-61-design-41.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-62-design-42.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-63-design-43.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-13-Vector-2.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-9-nectar-sleep-logo.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo domatron">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-10-Frame-2.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-11-Frame-3.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-12-cometchat.svg"
              loading="lazy"
              alt=""
            />
          </div>
        </div>
        <div
          className="marquee__element"
          // style="transform: translate3d(-39.083%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; will-change: transform;"
        >
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-64-design-44.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-65-design-45.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-66-design-46.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-13-Vector-2.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-9-nectar-sleep-logo.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo domatron">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-10-Frame-2.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-11-Frame-3.svg"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="marquee__logo">
            <img
              src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-12-cometchat.svg"
              loading="lazy"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionHeroBlock = ({ t }) => {
  return (
    <div id="hero" className="heroBlock">
      <div className="container">
        <div
          data-w-id="eaba7a0f-a465-906c-7bef-2dc700a79ac4"
          //  style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          className="container__800 m-auto hero"
        >
          <h1>{t("A Digital Agency with a Unique Approach")}</h1>
          <p className="sub-para-24">
            {t("Subscriptions for All. Pause or Cancel Anytime.")}
          </p>
          <div className="button__group">
            <a href="#pricinggrid" className="button hero w-inline-block">
              <div>{t("See plans")}</div>
            </a>
          </div>
          <div
            className="guarantee"
            dangerouslySetInnerHTML={{ __html: t("guaranteeBlock") }}
          ></div>
        </div>
      </div>
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-67-design-47.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        // data-w-id="88ac922f-e294-f08f-3bb5-de0e2b0d5499"
        alt=""
        className="image-43 image-right-side"
      />
      <img
        src="https://www.xiaokaup.com/digitechJoy/2024-03-08-img-68-design-48.svg"
        loading="lazy"
        // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
        // data-w-id="4dab2a26-fac7-cb3a-a724-a93ff68c1152"
        alt=""
        className="image-44 image-left-side"
      />
    </div>
  );
};

const LogoNavBlock = ({ t, locale, setLocale }) => {
  const [isShow, setIsShow] = useState(false);

  const logoNavElementRef = useRef<HTMLElement>(); // 创建一个ref来引用你的特定元素

  // 这个函数会在点击非指定元素时被触发
  const handleClickOutside = (event) => {
    if (
      !event.target.classList.contains("hamburger-menu") &&
      logoNavElementRef.current &&
      !logoNavElementRef.current.contains(event.target)
    ) {
      // 如果点击的不是指定元素（或其子元素），则执行以下操作
      setIsShow(false);
    }
  };

  useEffect(() => {
    // 组件挂载时添加事件监听器
    document.addEventListener("click", handleClickOutside, true);

    // 组件卸载时移除事件监听器
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []); // 空数组表示这个effect仅在组件挂载和卸载时运行

  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar w-nav logNavBlock"
    >
      <div className="container nav">
        <div>
          <a
            href="/"
            aria-current="page"
            className="nav__logo w-nav-brand w--current"
            aria-label="home"
          >
            <img
              src="https://www.xiaokaup.com/digitechJoy/logo-digitechjoy.png"
              loading="lazy"
              alt=""
            />
          </a>
        </div>
        <div>
          <nav
            ref={logoNavElementRef}
            role="navigation"
            className="nav-menu w-nav-menu"
            style={isShow ? {} : { display: "none" }}
          >
            <a href="#benefits" className="nav__link w-nav-link">
              {t("Benefits")}
            </a>
            <a href="#latest" className="nav__link w-nav-link">
              {t("Recent work")}
            </a>
            <a href="#sow" className="nav__link w-nav-link">
              {t("Scope of work")}
            </a>
            <a href="#pricing-2" className="nav__link w-nav-link">
              {t("Pricing")}
            </a>
            <a href="#faq" className="nav__link w-nav-link">
              {t("FAQs")}
            </a>
            <a
              href="https://billing.stripe.com/p/login/fZe7vv3Lw9Tz3NS144"
              // ms-hide-element="true"
              className="nav__link w-nav-link"
            >
              {t("Login")}
            </a>
            <a
              className="nav__link w-nav-link"
              onClick={() => {
                if (locale === ELocale.EN) {
                  setLocale(ELocale.FR);
                }
                if (locale === ELocale.FR) {
                  setLocale(ELocale.ZH);
                }
                if (locale === ELocale.ZH) {
                  setLocale(ELocale.EN);
                }
              }}
            >
              {locale === ELocale.EN && "🇬🇧"}
              {locale === ELocale.FR && "🇫🇷"}
              {locale === ELocale.ZH && "🇨🇳"}
            </a>
            <a
              href="/log-in"
              // eslint-disable-next-line react/no-unknown-property
              ms-profile="true"
              className="nav__link w-nav-link"
              style={style_hidden}
            >
              My account
            </a>
          </nav>
          <div
            className="menu-button w-nav-button"
            // style="-webkit-user-select: text;"
            aria-label="menu"
            role="button"
            // tabindex="0"
            aria-controls="w-nav-overlay-0"
            aria-haspopup="menu"
            aria-expanded="false"
          >
            <div
              className={`w-icon-nav-menu hamburger-menu ${
                isShow ? "clicked" : ""
              }`}
              onClick={() => {
                console.log("click hamburger-menu");
                setIsShow(!isShow);
              }}
            ></div>
          </div>
        </div>
      </div>
      <div
        className="w-nav-overlay"
        data-wf-ignore=""
        id="w-nav-overlay-0"
      ></div>
    </div>
  );
};

const LogoBlock = ({ locale, setLocale }) => {
  return (
    <div className="logo-nav logoBloack">
      <div></div>

      <a
        href="/"
        aria-current="page"
        className="link-block-2 w-inline-block w--current"
      >
        <img
          src="https://www.xiaokaup.com/digitechJoy/logo-digitechjoy.png"
          loading="lazy"
          alt=""
        />
      </a>

      <button
        type="button"
        className="language-switcher cursor-pointer bg-transparent rounded-md p-2 flex flex-row items-center hover:bg-gray-100 focus:bg-gray-200 focus:outline-none"
        onClick={() => {
          if (locale === ELocale.EN) {
            setLocale(ELocale.FR);
          }
          if (locale === ELocale.FR) {
            setLocale(ELocale.ZH);
          }
          if (locale === ELocale.ZH) {
            setLocale(ELocale.EN);
          }
        }}
      >
        {locale === ELocale.EN && "🇬🇧"}
        {locale === ELocale.FR && "🇫🇷"}
        {locale === ELocale.ZH && "🇨🇳"}
      </button>
    </div>
  );
};

export default SingleHomePageProject;
